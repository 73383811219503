import dayjs from 'dayjs';
import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import Loader from '../components/layouts/DashboardLayout/Loader';
import extractFromJWT from '../utils/extractFromJWT';
import { getToken, updateToken, onTokenUpdate } from '../utils/session';
import { CurrentUserFragment, useFetchCurrentUserQuery } from './Usersession.api';

export type ContextValue = {
    token: string | null;
    user: CurrentUserFragment | null;
};

export const Context = createContext<ContextValue | null>(null);

export const useUserSession = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useUserSession must be used within a UserSessionProvider');
    }

    return context;
};

export function useUser(shouldThrowIfNull: true): CurrentUserFragment;
export function useUser(shouldThrowIfNull: false | undefined): CurrentUserFragment | null;
export function useUser(shouldThrowIfNull?: boolean): CurrentUserFragment | null;
export function useUser(shouldThrowIfNull = false): CurrentUserFragment | null {
    const { user } = useUserSession(); // Assuming useUserSession is correctly defined and imported

    if (shouldThrowIfNull && !user) {
        throw new Error('User is not available');
    }

    return user;
}

export type UserSessionProviderProps = { children: ReactNode };

const UserSessionProvider = ({ children }: UserSessionProviderProps) => {
    // get the initial token from the session
    // forcefully run validation when retrieving it
    const [currentToken, setCurrentToken] = useState(() => getToken(true));

    // listen for token updates and update the state
    useEffect(() => onTokenUpdate(setCurrentToken), [setCurrentToken]);

    // query to fetch user document
    const { data, refetch, loading } = useFetchCurrentUserQuery({
        // use network only policy to avoid returning stale data
        fetchPolicy: 'cache-and-network',
    });

    // the following effect is responsible for refreshing the token
    useEffect(() => {
        if (!currentToken) {
            // there's nothing to be done if there is no token
            return () => undefined;
        }

        const { exp } = extractFromJWT(currentToken);
        const expirationDate = dayjs(exp * 1000);
        const delayBeforeExpiration = expirationDate.subtract(1, 'minute').diff(dayjs(), 'millisecond');

        // in browser the real type is `number`
        let timeoutId: NodeJS.Timeout | null = null;
        let mounted = true;

        const refreshToken = async () => {
            if (expirationDate.isBefore(dayjs())) {
                // we don't refresh if the token is expired
                timeoutId = null;
                updateToken(null);
            } else {
                // try to refresh the token
                // todo refresh call
                // eslint-disable-next-line no-lonely-if
                if (mounted) {
                    // if fail trigger a new refresh soon
                    timeoutId = setTimeout(refreshToken, 500);
                }
            }
        };

        timeoutId = setTimeout(refreshToken, delayBeforeExpiration);

        return () => {
            mounted = false;

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [currentToken]);

    // we want to refetch the user document whenever the token changed
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentToken]);

    const context = useMemo(
        (): ContextValue => ({
            token: currentToken,
            // if there's no token then we right away nullify the user document
            user: currentToken ? data?.currentUser || null : null,
        }),
        [currentToken, data]
    );

    if (context.token && !context?.user && loading) {
        return <Loader />;
    }

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default UserSessionProvider;
