import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFound from './components/common/NotFound';
import CenteredLayout from './components/layouts/CenteredLayout';
import DashboardLayout from './components/layouts/DashboardLayout';
import { useUser } from './contexts/UserSession';
import DummyError from './pages/DummyError';
import LoginPage from './pages/LoginPage';
import Inbox from './pages/MailInbox';
import ProfilePage from './pages/ProfilePage';
import SignOutPage from './pages/SignOutPage';

const RedirectToDashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/', { replace: true });
    }, [navigate]);

    return null;
};

const RedirectToLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/login/', {
            replace: true,
            state: { from: location.pathname },
        });
    }, [navigate, location.pathname]);

    return null;
};

const AppRoutes = () => {
    const user = useUser();

    if (!user) {
        return (
            <Routes>
                <Route element={<CenteredLayout />} path="/login/*">
                    <Route element={<LoginPage />} index />
                    <Route element={<NotFound homePath={null} />} path="*" />
                </Route>
                <Route element={<RedirectToLogin />} path="*" />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route element={<RedirectToDashboard />} path="/login/*" />
            <Route element={<DashboardLayout />} path="*">
                <Route element={null} index />
                <Route element={<Inbox />} path="inbox" />
                <Route element={<DummyError />} path="demo/500" />
                <Route element={<ProfilePage />} path="profile/authentication" />
                <Route element={<SignOutPage />} path="profile/signout" />
                <Route element={<NotFound />} path="*" />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
