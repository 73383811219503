import clsx from 'clsx';
import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';

type PrimaryButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    size?: 'small' | 'normal';
};

const PrimaryButton = ({ className, size = 'normal', ...props }: PrimaryButtonProps) => (
    <button
        className={clsx(
            'mt-1 inline-flex items-center gap-2 rounded-md bg-primary font-medium text-white hover:bg-opacity-90',
            size === 'small' && 'px-1 py-1',
            size === 'normal' && 'px-6 py-3',
            props.disabled && 'cursor-not-allowed opacity-50 hover:bg-opacity-100',
            className
        )}
        type="button"
        {...props}
    />
);

export default PrimaryButton;
