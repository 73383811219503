import { useEffect, startTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateToken } from '../../utils/session';

const SignOutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        startTransition(() => {
            updateToken(null);
            navigate('/login', { replace: true });
        });
    }, [navigate]);

    return null;
};

export default SignOutPage;
