
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BasicAuthenticationResponse": [
      "BasicAuthenticationSuccessfulResponse",
      "ErrorResponse"
    ],
    "Broadcast": [
      "DummyBroadcast"
    ]
  }
};
      export default result;
    