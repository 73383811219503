import { lazy } from '@loadable/component';
import { Suspense } from 'react';

const LazySubscriptionManager = lazy(() => import('./SubscriptionManager'));

const SubscriptionManager = () => (
    <Suspense fallback={null}>
        <LazySubscriptionManager />
    </Suspense>
);

export default SubscriptionManager;
