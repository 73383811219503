import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import CardTitle from './CardTitle';
import CenteredPageContent from './CenteredPageContent';
import PrimaryButton from './PrimaryButton';
import NotFoundImage from './not-found.svg?react';

export interface NotFoundProps {
    homePath?: string | null;
}

const NotFound = ({ homePath = '/' }: NotFoundProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <CenteredPageContent>
            <Card>
                <div className="mx-auto max-w-[410px] text-center">
                    <NotFoundImage />
                </div>
                <CardTitle>{t('common:pages:404:title')}</CardTitle>
                <p className="text-center font-medium">{t('common:pages:404:description')}</p>
                {homePath !== null && (
                    <div className="mt-6 text-center">
                        <PrimaryButton onClick={() => navigate('/')}>
                            <ArrowLeftIcon className="h-6 w-6" />
                            <span>{t('common:pages:404:buttonSpan')}</span>
                        </PrimaryButton>
                    </div>
                )}
            </Card>
        </CenteredPageContent>
    );
};

export default NotFound;
